const HeaderData = [
    {
        title: 'Home',
        link: "/"
    },
    {
        title: 'Services',
        link: "/Services"

    },
    {
        title: 'Contact',
        link: "/Contact"
    },
    {
        title: 'Circuit',
        megamenu: [
            {
                title: 'Morondava',
                dropdown: [
                    {
                        title: 'BAOBAB TOUR',
                        link: '/tours/morondava/674dc6dd96cc484e51c6a444'

                    },
                    {
                        title: 'KIRINDY FOREST AND BAOBAB TOURS',
                       link: '/tours/morondava/kirindy'
                    },
                    {
                        title: 'TSINGY ADVENTURE TRIP',
                        link: '/tours/morondava/tsingy'
                    },

                ],
            },
            {
                title: 'Nosy be',
                dropdown: [
                    {
                        title: 'Adinala Beach',

                        link: '#',
                    },

                ],
            },
            {
                title: 'Saint Marie',
                dropdown: [
                    {
                        title: 'Saint Marie',
                        link: '#',
                    },


                ],
            },

        ]
    },



]

export default HeaderData