import React from 'react';

// Libraries
import { Row, Col, Container, Dropdown, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Components
import Header, { HeaderNav, Menu, SearchBar, Topbar } from '../../Components/Header/Header';
import SocialIcons from '../../Components/SocialIcon/SocialIcons';

// Data
const SocialIconsData = [
    {
        color: "#0038e3",
        link: "https://www.facebook.com/",
        icon: "fab fa-facebook-f"
    },
    {
        color: "#E1306C",
        link: "https://www.instagram.com/",
        icon: "fab fa-instagram"
    },
    {
        color: "#010101",
        link: "https://www.tiktok.com/",
        icon: "fab fa-tiktok"
    },
    {
        color: "#FF0000",
        link: "https://www.youtube.com/",
        icon: "fab fa-youtube"
    }
];

const NavbarComponent = () => {
    return (
        <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
            <Topbar className="bg-lightgray border-b border-[#0000001a] sm:hidden md:px-[15px]">
                <Container fluid="lg">
                    <Row>
                        <Col className="col-12 col-sm-auto text-center items-center flex text-sm-start me-auto">
                            <SocialIcons theme="social-icon-style-01" size="xs" iconColor="dark" data={SocialIconsData} />
                        </Col>
                        <Col className="col-auto flex xs:hidden text-right">
                            <div className="top-bar-contact flex">
                                <a
                                    href="tel:+261328653204"
                                    className="border-l border-[#0000001a] flex py-[13px] px-[18px] text-sm justify-center items-center"
                                >
                                    <i className="feather-phone-call mr-[8px] text-md relative text-fastblue"></i>
                                    +261 32 86 532 04
                                </a>

                                <span className="border-l border-[#0000001a] py-[13px] px-[18px] text-sm flex justify-center items-center">
                                    <i className="far fa-envelope mr-[8px] text-md relative text-fastblue"></i>
                                    <a aria-label="mail" href="mailto:info@madagascar-bamtours.com" className="hover:text-darkgray">info@madagascar-bamtours.com</a>
                                </span>
                            </div>
                            <div className="header-language pl-0 text-[17px] align-middle inline-block md:hidden landscape:md:hidden">
                                <Dropdown className="border-l border-r border-solid border-[#0000001a] h-full text-[#828282]">
                                    <Dropdown.Toggle className="rounded-none uppercase w-auto h-full font-normal text-xs leading-[11px] border-[0px] m-0 dropdown-toggle after:none sm-w-100 items-center flex px-[18px] py-[14px] bg-transparent" id="dropdown-basic">
                                        <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-0"><img height={16} width={16} src="/assets/img/country-flag-16X16/england.png" alt="EN" /></span>EN <span className="caret"></span> <i className="fas fa-angle-down top-[-1px] ml-[5px] align-middle relative after:content-[' ']"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-3">
                                            <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/france.png" alt="France" /></span>Français
                                        </Dropdown.Item>
                                        <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-4">
                                            <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/china.png" alt="Chinois" /></span>中文
                                        </Dropdown.Item>
                                        <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-5">
                                            <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/arabic.png" alt="Arabe" /></span>عربي
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Topbar>
            {/* Header Start */}
            <HeaderNav theme="light" bg="white" expand="lg" containerClass="sm:px-0" className="py-[0px] md:px-0">
                <Col className="col-6 col-lg-2 me-auto ps-lg-0">
                    <Link aria-label="header logo" className="flex items-center" to="/">
                        <Navbar.Brand className="inline-block p-0 m-0">
                            <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/data/bg.png' data-rjs='/assets/img/data/bg.png' alt='logo' />
                            <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/data/bg.png' data-rjs='/assets/img/data/bg.png' alt='logo' />
                            <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/data/bg.png' data-rjs='/assets/img/data/bg.png' alt='logo' />
                        </Navbar.Brand>
                    </Link>
                </Col>
                <Navbar.Toggle className="order-last md:px-[25px] md:ml-[12px] sm:ml-0">
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                </Navbar.Toggle>
                <Navbar.Collapse className="col-auto col-lg-8 menu-order px-lg-0 justify-center">
                    <Menu />
                </Navbar.Collapse>
                <Col className="col-auto col-lg-2 text-end pe-0 font-size-0">
                    <SearchBar className="xs:pl-[15px]" />
                </Col>
            </HeaderNav>
            {/* Header End */}
        </Header>
    );
};

export default NavbarComponent;
