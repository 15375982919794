import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"
import NavbarComponent from "./Components/Navbar/NavbarComponent";


const TravelAgencyPage = lazy(() => import("./Pages/Home/TravelAgency"))


const WhatWeOfferPage = lazy(() => import("./Pages/Services/WhatWeOfferPage"))

const ContactUsClassicPage = lazy(() => import("./Pages/Contact/ContactUsClassicPage"))

const NotFoundPage = lazy(() => import("./Pages/404"))

// Shop Pages

const SingleProduct = lazy(() => import("./Pages/Shop/SingleProduct"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        <NavbarComponent />
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<TravelAgencyPage />} />
                  <Route path="/Services" element={<WhatWeOfferPage />} />
                  <Route path="/Contact" element={<ContactUsClassicPage />} />

                  <Route path="tours">
                    <Route path="morondava/:idCirCuit" element={<SingleProduct />} />
                  </Route>

                  <Route path="*" element={<NotFoundPage />} />




                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
        {/* Section Start */}
        {/* <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue overflow-visible" /> */}
        {/* Section End */}
      </div>
    </GlobalContext.Provider>
  )
}

export default App;